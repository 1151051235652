.content-overlay {
  position: fixed !important;
  margin: 0px !important;
  padding: 0px !important;
  left: 0px;
  top: 0px;
  width: 100%;
  height: 100%;
  background-color: var(--white);
  opacity: 0.75;
}
.content-container {
  position: fixed !important;
  margin-top: 0px !important;
  overflow: hidden;
  background-color: var(--white);
}

.content-container.desktop {
  top: 50%;
  width: 850px;
  height: calc(100% - 20px);
  margin-left: auto;
  margin-right: auto;
  transform: translate(0%, -50%);
  outline: 2px solid var(--black);
}

.content-container.mobile {
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.container-bar, .container-title, .close-btn {
  position: absolute !important;
  margin: 0px !important;
  padding: 0px !important;
  top: 0px;
  right: 0px;
  height: 36px;
 }

.container-bar {
  top: 0px;
  left: 0px;
  width: inherit;
  outline: 2px solid var(--black);
  background-color: var(--white);
}

.container-title {
  left: 8px;
  width: calc(100% - 44px);
  font-family: var(--mono);
  font-weight: 400;
  font-style: normal;
  text-align: left;
  vertical-align: middle;
  font-size: 20px;
  line-height: 36px;
  letter-spacing: 0.05em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close-btn {
  position: absolute !important;
  margin: 0px !important;
  padding: 0px !important;
  top: 0px;
  right: 0px;
  width: 36px;
  height: 36px;
  font-family: var(--sans);
  font-weight: 400;
  font-style: normal;
  text-align: center;
  vertical-align: middle;
  font-size: 30px;
  line-height: 33px;
  outline: 2px solid var(--black);
}
.close-btn:link, .close-btn:visited {
  color: var(--black) !important;
  background-color: var(--white) !important;
}
.close-btn:hover {
  color: var(--white) !important;
  background-color: var(--red) !important;
}
.close-btn:active {
  color: var(--red) !important;
  background-color: var(--black) !important;
}

.inner-content {
  margin-top: 36px !important;
  margin-bottom: 10px !important;
  padding: 0px !important;
  top: 0px;
  left: 0px;
  width: calc(100%);
  height: calc(100% - 36px);
  overflow: scroll;
}
.inner-content > * {
  margin-left: 10px !important;
  margin-right: 10px !important;
}
